export class passwordSetting{
    id:string;
    createdBy:reference=new reference();
    updatedBy:reference=new reference();
    setting:setting=new setting();
}

export class reference {
    id: string;
    reference: string;
    display: string;
}

export class setting{
    passwordexpire:string;
}

