import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  saveSession(key , value){
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getSession(key){
    return JSON.parse(sessionStorage.getItem(key));
  }

  clearSession(key){
    sessionStorage.removeItem(key)
    sessionStorage.clear();
  }
  
}
