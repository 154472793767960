import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { SessionStorageService } from '../sessionStorage/session-storage.service';
import { loginhistory } from 'src/app/entities/loginHistory';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private isUserLoggedIn;
  username: string;
  private config = environment;

  constructor(
    private http: HttpClient, private sessionStorageService: SessionStorageService
  ) {
  }



  getById(id) {
    return this.http.get<any>(this.config.baseurl + "person/" + id);
  }


  getUsersForSuperAdmin() {
    return this.http.get<any[]>(this.config.baseurl + "person")
  }

  getUsers() {
    var obj = {
      groups: this.getGroups()
    }
    return this.http.post<any[]>(this.config.baseurl + "usersbygroup", obj)
  }

  searchUser(keyword) {
    let params = new HttpParams()
      .set('keyword', keyword)
    return this.http.get<any[]>(this.config.baseurl + "user/search", { params })
  }

  deleteUser(ids) {
    var obj = {
      id: ids
    }
    return this.http.post<any>(this.config.baseurl + "user/delete", obj);
  }

  // createUser(data) {

  //   return this.http.post<any>(this.config.serviceurl + "person/save",data);
  // }

  saveUser(data) {
    return this.http.post<any>(this.config.baseurl + "person/save_auth", data);
  }

  updateUser(data) {
    return this.http.put<any>(this.config.baseurl + "person/update_auth", data);
  }

  // updatePerson(data) {
  //   return this.http.put<any>(this.config.serviceurl + "person/update",data);
  // }

  CheckUserNameAvailability(username) {
    let params = new HttpParams()
      .set('email', username)
    return this.http.get<any>(this.config.baseurl + "usercheck", { params });
  }

  getInvitationLogs(userid) {
    return this.http.get<any[]>(this.config.baseurl + "invitationbypatient/" + userid)
  }

  getGroupsByUser(userid) {
    return this.http.get<any[]>(this.config.baseurl + "groupsbyuser/" + userid)
  }

  getRolesByUser(userid) {
    return this.http.get<any[]>(this.config.baseurl + "rolesbyuser/" + userid)
  }

  resetPassword(obj) {
    return this.http.post(this.config.baseurl + "updatepassword", obj)
  }

  assignGroupsToUser(userid, groupids) {
    var obj = {
      id: groupids
    }
    return this.http.put<any>(this.config.baseurl + "assigngroup/" + userid, obj);
  }

  assignRolesToUser(userid, roleids) {
    var obj = {
      id: roleids
    }
    return this.http.put<any>(this.config.baseurl + "assignrole/" + userid, obj);
  }

  public Login(user): Observable<any> {
    return this.http.post<any>(this.config.baseurl + "auth", { username: user.username, password: user.password })
      .pipe(
        map(data => {
          this.sessionStorageService.saveSession('jwtToken', data.credentials.access_token);
          return data;
        })
      );
    ;
  }

  setUserLoggedIn() {
    this.isUserLoggedIn = true;
    localStorage.setItem("User", "admin");
  }

  setUserLoggedOut() {
    this.isUserLoggedIn = true;
    localStorage.removeItem("User");
    localStorage.clear();
  }

  public getLoginSession(): any {
    return this.sessionStorageService.getSession("LoginSession");
  }



  public setUserRoleDetails() {

    var rolesArray = this.getUserRolesArray();

    this.sessionStorageService.saveSession("roles", rolesArray);

  }

  getGroups() {
    return this.sessionStorageService.getSession('groups');
  }


  public getUserRolesArray(): any {
    var user = this.sessionStorageService.getSession("decodedToken");
    var userroles = user.roles;

    this.sessionStorageService.saveSession('groups', user.groups)

    var roles: any[] = [];

    if (userroles.length != 0) {
      userroles.forEach(element => {
        roles.push(element)
      });
    }
    return roles;
  }

  getSessionUser(): any {
    return this.sessionStorageService.getSession("user");
  }

  saveLoginHistory(obj: loginhistory) {
    return this.http.post<any>(this.config.baseurl + "loginhistory/save", obj);
  }

  updateLoginHistory(obj: loginhistory) {
    return this.http.put(this.config.baseurl + "loginhistory/update/" + obj.id, obj);
  }


}






export enum Modules {
  group = 4,
  user = 5,
  role = 6
}