import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PasswordValidation } from './PasswordValidation';

@Component({
  selector: 'app-validate-user',
  templateUrl: './validate-user.component.html',
  styleUrls: ['./validate-user.component.css']
})
export class ValidateUserComponent implements OnInit {
  validateOTPForm:FormGroup;
  registrationForm:FormGroup;
  verified:boolean=false;
  submitted = false;
  isSubmitted = false;


  constructor(    
    private fb: FormBuilder,
    private router: Router
    ) { }

  ngOnInit() {

  this.validateOTPForm = this.fb.group({
    otp: ['',[Validators.required]],
  })

  this.registrationForm = this.fb.group({
    email: ['',[Validators.required]],
    password: ['',[Validators.required]],
    cpassword: ['']
    
  }, {
    validator: PasswordValidation.MatchPassword
  })
  }

  validateUser(data){
    this.submitted = true;
    if(data.otp){
      this.verified=true;
    } 
  }

  registerUser(data){

  }

}
