import { Component, OnInit, OnDestroy } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { user } from '../entities/user';

import { SessionStorageService } from '../services/sessionStorage/session-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginPayload } from '../entities/loginpayload';
import { loginhistory } from '../entities/loginHistory';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  ologgedInUser: any // =new user();
  username: any;
  password: any;
  ispermitted: boolean = false;


  constructor(
    private fb: FormBuilder,
    private userService:UserService,
    private sessionStorageService:SessionStorageService,
    private router: Router,

   

  ) {

   
    this.loginForm = this.fb.group({
      username: ["", ""],
      password: ["", ""],
    });
  }


  subscription: Subscription = new Subscription();

  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  login(data) {

    if (data.username != '' && data.password != '') {
      this.subscription.add(
        this.userService.Login(data).subscribe((res) => {          
          if(res) {
          if(res.user.superuser || res.user.adminuser) {
              this.saveUserInfo(res);           
          }
          else {
            this.ispermitted = true;
          }
        }
        else {
          this.username = "Please enter a valid username";
          this.password = "please enter a valid password";
          this.router.navigate(['']);
       }
        },
          (ex) => {
            this.username = "Please enter a valid username";
            this.password = "please enter a valid password";
          })
      );
    }

  }

    //func to save login history
    saveLoginHistory(payload: LoginPayload) {
      var obj = new loginhistory();
      obj.userid = payload.user.id;
      obj.username = payload.user.email;
      obj.project = "qpathways-usermanagement";
      
      this.userService.saveLoginHistory(obj).subscribe((res) => {
        obj.id = res.id;
        this.sessionStorageService.saveSession("LoginSession", obj);
      })
    }

  saveUserInfo(data : LoginPayload) {
   this.saveLoginHistory(data);
    var userObj = new user();

    userObj.id = data.user.id;
    userObj.email = data.user.email;
    // userObj.enabled = data.user.enabled;
    userObj.family_name = data.user.family_name;
    userObj.firebaseid = data.user.firebaseid;
    userObj.given_name = data.user.given_name;
    userObj.entity.display = data.user.name;
    userObj.picture = data.user.picture;
    userObj.entity.image = (data.user.picture) ? data.user.picture:'';
    userObj.entity.id = '';
    userObj.token = data.credentials.access_token;  
    userObj.superuser = data.user.superuser;  
    userObj.adminuser = data.user.adminuser;
    
    this.sessionStorageService.saveSession("user",userObj);    

    var myRawToken = data.credentials.access_token;
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(myRawToken);

    this.sessionStorageService.saveSession("decodedToken", decodedToken);
    this.userService.setUserRoleDetails();

    
    this.router.navigate(['accounts/groups']);
  } 

  ngOnInit() {
  }

}
