export class user {

    id: string;
    role:string;
    email: string;
    entity: entity;
    token: string;
    superuser: boolean;
    adminuser: boolean;

enabled: boolean;
family_name: string;
firebaseid: string;
given_name: string;
name: string;
picture: null

    constructor(){
        this.entity=new entity();
    }

}
export class entity {
    id: string;
    display: string;
    image: string;
}
