import { Component, OnInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription, Observable } from 'rxjs';
import { SessionStorageService } from '../services/sessionStorage/session-storage.service';
import { UserSessionService } from '../Shared Components/user session mgnt & service/user-session.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {


  @ViewChild("list") list;

  Patientdata: string;
  user: any;
  notification: Observable<any>;

  loaderFlag: boolean = true;
  public source: any[] = [];
  public data: any[] = [];
  public userrole: string = "";
  exception: any;
  flgNotification: boolean = true;
  UserRole: string;
  UserRoleDesc: string;
  notObj: any;
  fac: any[] = [];

  activetab = 'feeds';
  subscription: Subscription = new Subscription();

  message = null;
  tempMessage = null;
  sMessage: string = "";


  sFromDisplay : string = "";

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private userservice: UserService,
    private sessionService: UserSessionService
  ) {
  }
  
  ngOnInit() {

    
    this.sessionService.isSessionExpire
    .subscribe((response) => {
      if(response) {
        this.clearSession();
      }
    })

    this.user = JSON.parse(sessionStorage.getItem("user"));    
  }

  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }


  getActiveTab(tabname: string) {
    this.activetab = tabname
  }


  
  LogOut() {

    this.sessionService.isSessionExpire.next(true);


    // let usertoken = this.userSessionStorageService.getSession("usertoken");
    // this.messagingService.deleteToken(usertoken).subscribe(res => {
    //   this.userSessionStorageService.clearSession("usertoken");
    //   this.userSessionStorageService.clearSession("user");
    //   this.router.navigate(['']);
    // },
    //   (ex) => {
    //     this.userSessionStorageService.clearSession("usertoken");
    //     this.userSessionStorageService.clearSession("user");
    //     this.router.navigate(['']);
    //   });

    // this.sessionService.clearSession("user");
    // this.router.navigate(['']);
  }

  clearSession() {
    var obj = this.userservice.getLoginSession();    
    this.userservice.updateLoginHistory(obj).subscribe((res) => {
      this.sessionStorageService.clearSession("user");
      this.router.navigate(['']);
    },
    (ex) => {
      this.sessionStorageService.clearSession("user");
      this.router.navigate(['']);
    })
  }

  
}
