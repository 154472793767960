export class role {

    id: string;
    name: string;
    description: string;
    code: string;
    createdby: string;
    active: boolean;
    premissions: any[]=[];
    type: string;
}