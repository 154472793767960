import { LoginComponent } from "./login/login.component";
import { Routes } from "@angular/router";
import { AccountsComponent } from "./core/Account Components/accounts/accounts.component";
import { GroupsComponent } from "./core/Groups Components/groups/groups.component";
import { RolesComponent } from "./core/Roles Components/roles/roles.component";
import { PermissionsComponent } from "./core/Permission Components/permissions/permissions.component";
import { UsersComponent } from "./core/User Components/users/users.component";
import { RoleSaveComponent } from "./core/Roles Components/role-save/role-save.component";
import { GroupSaveComponent } from "./core/Groups Components/group-save/group-save.component";
import { GroupDetailsComponent } from "./core/Groups Components/group-details/group-details.component";
import { RoleDetailsComponent } from "./core/Roles Components/role-details/role-details.component";
import { UserSaveComponent } from "./core/User Components/user-save/user-save.component";
import { UserDetailsComponent } from "./core/User Components/user-details/user-details.component";
import { ValidateUserComponent } from "./validate-user/validate-user.component";
import { AuthGuard } from "./services/auth guard/auth.guard";
import { SettingsComponent } from "./core/Settings/settings/settings.component";
import { PasswordSettingsComponent } from "./core/Settings/password-settings/password-settings.component";


export const authRoutes: Routes = [

    {
        path: 'accounts',
        canActivate: [AuthGuard],
        component: AccountsComponent,
        children: [
            { path: 'groups', component: GroupsComponent },
            { path: 'groups/register', component: GroupSaveComponent },
            { path: 'groups/:id', component: GroupSaveComponent },
            { path: 'groups/details/:id', component: GroupDetailsComponent },

            { path: 'roles', component: RolesComponent },
            { path: 'roles/register', component: RoleSaveComponent },
            { path: 'roles/:id', component: RoleSaveComponent },
            { path: 'roles/details/:id', component: RoleDetailsComponent },

            { path: 'permissions', component: PermissionsComponent },
            
            { path: 'users', component: UsersComponent },
            { path: 'users/register', component: UserSaveComponent },
            { path: 'users/:id', component: UserSaveComponent },
            { path: 'users/details/:id', component: UserDetailsComponent },
            
        ],
        
    },
    { path: 'settings',
    canActivate: [AuthGuard],
     component: SettingsComponent,
     children: [
            { path: 'password-setting', component: PasswordSettingsComponent },
            ], 
    },
]


export const publicRoutes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'validate', component: ValidateUserComponent },
]
