export class User {
    id: string;
    identifier: string;
    fullname?: string;
    name: fullname = new fullname();
    birthdate: string;
    gender: string;
    address: addressdeatail = new addressdeatail();
    maritalStatus: string;
    managingOrganization: organization = new organization();
    url?: string;
    active: any;
    roles: string[] = [];
    groups: string[] = [];
    telecom: contactdetail = new contactdetail();
    username: string;
    password: string;
    note: string;
    group?: string;
    groupname?: string;
    firebaseid?: string;
    applicationlink: string;
    link: linkObj = new linkObj();
    linkresource: string;
}


export class fullname {
    firstname: string;
    middlename: string;
    lastname: string;
}

export class addressdeatail {
    address1: string;
    address2: string;
    city: string
    state: string
    zip: string
    country: string
}

export class organization {
    id: string;
    display: string;
}

// export class imageForm{
//     id?:string;
//     contentType:string;
//     data:string;
// }

// export class code{

//     id:string;
//     display:string;

// }

export class contactdetail {

    mobile: string;
    homephone: string;
    phone: string;
    emailid: string;

}

export class linkObj {
    id: string;
}
