import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  roleStorage: any;

  rolePermissions: any[]=[];
  
  constructor() { }
}
