import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GroupService } from 'src/app/services/group/group.service';
import { group } from 'src/app/entities/group';

@Component({
  selector: 'app-group-save',
  templateUrl: './group-save.component.html',
  styleUrls: ['./group-save.component.css']
})
export class GroupSaveComponent implements OnInit {

  public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];

  form: FormGroup;
  groupid: string;
  user:any;

  groupsData: any[]=[];
  groupsDataStorage: any[]=[];

  constructor(
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private service: GroupService
  ) { }

  ngOnInit() {

    this.user = JSON.parse(sessionStorage.getItem("user"));

    this.form =this.fb.group({
      name:['',Validators.required],
      description:'',
      memberof:[],
      isSysGroup: false
    })
    this.getAllGroups();

    this.activateRoute.params.subscribe((param) => {
      if(param.id) {
        this.groupid = param.id;
        this.getGroupDetails();
      }
    })
  }

  getAllGroups(){

    if(this.user.superuser) {
      this.service.getGroupsForSuperAdmin().subscribe((response) => {
        this.groupsData =  response;      
        this.groupsDataStorage = response;
      })
    }else{
      this.service.getGroups().subscribe((response) => {
        this.groupsData =  response;      
        this.groupsDataStorage = response;
      })
    }

 
  }

  handleFilter(id) {
    this.groupsData = 
    this.groupsDataStorage.filter((s) => s.name.toLowerCase().indexOf(id.toLowerCase()) !== -1);
  }

  getGroupDetails(){
    this.service.getById(this.groupid).subscribe((response) => {            
      this.form.patchValue({
        name:response.name,
        description:response.description,
        memberof:response.members,
        isSysGroup:(response.type === "system") ? true:false
      })
    })
  }

  saveData(data){
    var obj = new group();

    obj.id = this.groupid ;
    obj.createdby =  this.user.id;
    obj.description = data.description;
    obj.active = true;
    obj.members = data.memberof;
    obj.name = data.name;
    obj.type = (data.isSysGroup) ?  "system":"default";
    obj.memberof = (data.memberof) ? 'child':'parent';


    if(this.groupid) {
      this.service.updateGroup(obj).subscribe((response) => {
        this.router.navigate(['accounts/groups'])
      })
    }
    else {      
      this.service.createGroup(obj).subscribe((response) => {
        this.router.navigate(['accounts/groups'])
      })
    }
  }

  goBack() {
    if(this.groupid){
      this.router.navigate(['accounts/groups/details',this.groupid])
    }
    else {
      this.router.navigate(['accounts/groups'])
    }
  }

  

}

