import { Component, OnInit, HostListener } from '@angular/core';
import { UserSessionService } from './Shared Components/user session mgnt & service/user-session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'UserManagement';

  constructor(private sessionService: UserSessionService) {
  }
  ngOnInit() {

  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {    
    this.sessionService.notifyUserAction();
  }
}
