import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserMgntService } from '../usremgnt.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private config = environment;


  constructor(
    private http: HttpClient,
    private usermgntService: UserMgntService
    ) {
  }

  getById(id) {
    return this.http.get<any>(this.config.baseurl + "role/"+id);
  }

  getAllPermissions(){
    return this.usermgntService.getPermissions();
  }

  searchPermission(keyword){
    return this.usermgntService.searchPermission(keyword);
  }
  

  getRoles() {
    return this.http.get<any[]>(this.config.baseurl + "role")
  }

  searchRoles(keyword) {
    let params = new HttpParams()
      .set('keyword', keyword)
    return this.http.get<any[]>(this.config.baseurl + "role/search", { params })
  }

  deleteRoles(ids){
    var obj = {
      id:ids
    }
    return this.http.post<any>(this.config.baseurl + "role/delete",obj);
  }

  addPermissionsToRole(roleid,permission_ids: string[]){
    var obj ={
      id:permission_ids
    }
    return this.http.post<any>(this.config.baseurl + "addpermission/"+roleid,obj);
  }

  removeRolePermissions(roleid,permission_ids: string[]){
    var obj ={
      id:permission_ids
    }
    return this.http.post<any>(this.config.baseurl + "removepermission/"+roleid,obj);
  }

  getPermisisonsForrole(roleid){
    return this.http.get<any[]>(this.config.baseurl + "permissionbyroleid/"+roleid);
  }

  
  createRole(role) {
    return this.http.post<any>(this.config.baseurl + "role",role);
  }

  updateRole(role) {
    return this.http.put<any>(this.config.baseurl + "role",role);
  }
  
  getUsersByRole(roleid) {
    return this.http.get<any[]>(this.config.baseurl + "personbyrole/"+roleid);
  }

  checkCodeAvailability(code){
    let params = new HttpParams()
    .set('code', code)
    
     return this.http.get<any>(this.config.baseurl + "rolecheck",{params});
  }

}
