export const environment = {
  production: true,
  baseurl:'https://api.qpathways.com/auth/',
  firebase:{
    apiKey: "AIzaSyBbl1egno0GVIAWLP1_vr-qOeJd5iKZvhg",
    authDomain: "qpathways-dev.firebaseapp.com",
    databaseURL: "https://qpathways-dev.firebaseio.com",
    projectId: "qpathways-dev",
    storageBucket: "qpathways-dev.appspot.com",
    messagingSenderId: "413178660353"
  },
  env:"prod"
};
