import { Component, OnInit, Input } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { UserMgntService } from 'src/app/services/usremgnt.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { user } from 'src/app/entities/user';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {

  @Input() roleid: string;

  form: FormGroup;

  public gridData: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  private res: any;
  public items: any[];
  public presentData: any[];
  
  public sort: SortDescriptor[] = [{
    field: 'facility.display',
    dir: 'asc'
  }];

  selectableSettings: any
  public checkboxOnly = true;
  public mode = 'multiple';

  public mySelection: string[] = [];
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.id;
  }

  user = new user();
  
  constructor(
    private fb: FormBuilder,
    private service: UserMgntService,
    private userService: UserService) { 
  }

  ngOnInit() {
    this.user = this.userService.getSessionUser();
    this.setSelectableSettings();
    this.form = this.fb.group({
      keyword:''
    })
    if(this.user.superuser) {
    this.getAll();
    }
  }

   //deafult setting for patient selection grid
   public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode
    };
  }
  
  searchRecord() {
    if(this.form.get('keyword').value){      
      this.mySelection = [];
      this.service.searchPermission(this.form.get('keyword').value).subscribe((response) => {
        this.items = response;
        this.loadData();
      },
      err => {
        this.items=[];
        this.loadData();
        console.log(err);
      });
    }
    else {
      this.mySelection = [];
      this.items = this.presentData;
      this.loadData();
    }
  }

  getAll(){
    this.service.getPermissions().subscribe((data) => {
      this.presentData = data;
      this.items=data;
      this.loadData();
    },
    err => {
      this.items=[];
      this.loadData();
      console.log(err);
    });
  }

  
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadData();

  }

  private loadData(): void {
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    }
  }

  
  onSelect(event) {
    if (event.selected) {
      this.mySelection.push(event.selectedRows[0].dataItem.id);
    } else {
      var id = event.deselectedRows[0].dataItem.id;
      var i = this.mySelection.findIndex(item => item == id);
      this.mySelection.splice(i,1);
    }
  }

}
