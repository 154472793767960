import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GroupService } from "src/app/services/group/group.service";
import { groupPermission } from "src/app/entities/Permission Models & Enums/group permissions/grouppermission.model";
import { Subscription, of, Subject } from "rxjs";
import { UserpermissionsService } from "src/app/services/user permissions/userpermissions.service";
import { Modules } from "src/app/services/user/user.service";
import {
  GridDataResult,
  PageChangeEvent,
  RowArgs
} from "@progress/kendo-angular-grid";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { groupTreeStructure } from "src/app/entities/groupTreeStructure";

@Component({
  selector: "app-group-details",
  templateUrl: "./group-details.component.html",
  styleUrls: ["./group-details.component.css"]
})
export class GroupDetailsComponent implements OnInit {
  public gridData: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  public items: any[] = [];

  public sort: SortDescriptor[] = [
    {
      field: "fullname",
      dir: "asc"
    }
  ];

  selectableSettings: any;
  public checkboxOnly = true;
  public mode = "multiple";

  groupid: string;
  groupObj: any;

  groupParents: any[] = [];
  groupChildrens: any[] = [];
  groupUsers: any[] = [];

  permissions: groupPermission = new groupPermission();
  subscription: Subscription = new Subscription();

  groupTree: groupTreeStructure[] = [];

  public hasChildren = (item: any) => item.childs && item.childs.length > 0;
  public fetchChildren = (item: any) => of(item.childs);
  public isExpanded = (dataItem: any, index: string) => {
    return true;
  };

  public expandedKeys: any[] = [];

  public data: any[] = [];

  constructor(
    private activateRoute: ActivatedRoute,
    private service: GroupService,
    private router: Router,
    private permissionService: UserpermissionsService
  ) {}

  ngOnInit() {
    this.permissionService.getUserPermissions(Modules.group);
    this.subscription.add(
      this.permissionService.groupPermissions.subscribe(res => {
        this.permissions = res;
      })
    );

    this.activateRoute.params.subscribe(param => {
      if (param.id) {
        this.groupid = param.id;
        this.getGroupDetails().subscribe(response => {
          if (response) {
            this.getGrouptreeStructure(param.id);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData() {
    this.gridData = {
      data: orderBy(this.items, this.sort),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadUsers();
  }

  private loadUsers(): void {
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length
    };
  }

  //deafult setting for users selection grid
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode
    };
  }

  getGroupUsers(id) {
    this.service.getGroupUsers(id).subscribe(
      response => {
        if (response) {
          // this.groupUsers = response;
          this.items = response;
          this.loadUsers();
        }
      },
      err => {
        this.items = [];
        this.loadUsers();
        console.log(err);
      }
    );
  }

  getGroupDetails(): Subject<boolean> {
    let returnSubject = new Subject<boolean>();
    this.service.getById(this.groupid).subscribe(
      response => {
        this.groupObj = response;
        returnSubject.next(true);
      },
      err => {
        console.log(err);
      }
    );
    returnSubject.next(false);
    return returnSubject;
  }

  getGrouptreeStructure(id) {
    this.service.getGrouptree(id).subscribe(
      response => {
        if (response.status == 200) {
          this.data = [
            {
              name: this.groupObj.name,
              childs: response.body
            }
          ];

          this.groupTree = response.body;
          // this.groupParents = response.parenttree;
          // this.groupChildrens = response.childtree;
        }
      },
      err => {
        console.log(err);
        this.data = [
          {
            name: this.groupObj.name,
            childs: null
          }
        ];
      }
    );
  }

  goToUserDetails(id) {
    this.router.navigate(["accounts/users/details", id]);
  }
}
