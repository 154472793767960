import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserMgntService } from 'src/app/services/usremgnt.service';

@Component({
  selector: 'app-account-save',
  templateUrl: './account-save.component.html',
  styleUrls: ['./account-save.component.css']
})
export class AccountSaveComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedrouter: ActivatedRoute,
    private datePipe: DatePipe,
    private facilityservice: UserMgntService
    )
  { }

  ngOnInit() {
    this.form = this.fb.group({
      firstname:'',
      lastname:'',
      email:''
    })
  }

}
