import { reference } from "src/app/entities/reference";

export class PatientInvitation{
    emailid:string;
    mobile:string;
    message:string;
    sendby:reference =new reference();
    patientid:string;
    module:string; 
    resourcetype:string;
    password?: string;
}