import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, Modules } from 'src/app/services/user/user.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoleService } from 'src/app/services/role/role.service';
import { GroupService } from 'src/app/services/group/group.service';
import { userPermission } from 'src/app/entities/Permission Models & Enums/user permissions/userpermission.model';
import { Subscription, Subject } from 'rxjs';
import { UserpermissionsService } from 'src/app/services/user permissions/userpermissions.service';
import { user } from 'src/app/entities/user';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  public gridData: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  private res: any;
  public items: any[];
  public itemsStorage: any[];

  public sort: SortDescriptor[] = [{
    field: 'fullname',
    dir: 'asc'
  }];


  userid: string;
  userObj: any;

  form: FormGroup;

  rolesData: any[] = [];
  rolesDataStorage: any[] = [];

  groupsData: any[] = [];
  groupsDataStorage: any[] = [];


  permissions: userPermission = new userPermission();
  subscription: Subscription = new Subscription();

  user = new user();

  isAdminUser: boolean = false;

  isRoleLoaded = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private service: UserService,
    private roleService: RoleService,
    private groupsService: GroupService,
    private permissionService: UserpermissionsService,
    private router: Router
  ) { }

  ngOnInit() {

    this.user = this.service.getSessionUser();
    this.permissionService.getUserPermissions(Modules.user);
    this.subscription.add(
      this.permissionService.userPermissions.subscribe((res) => {

        this.permissions = res;
        if (this.permissions.assignGroup) {
          this.getAllGroups();
        }
        if (this.permissions.assignRole) {
          this.getAllRoles()
        }
      })
    )


    this.form = this.fb.group({
      member: ['', [Validators.required]]
    })

    this.activateRoute.params.subscribe((param) => {
      if (param.id) {
        this.userid = param.id;
        this.getUserDetails();
        this.getUserRoles();
        // this.getInvitaionLog();
        // this.getUserGroups();
      }
    })
  }

  clickOnRoles() {
    this.isRoleLoaded.next(true);
  }

  ngOnDestroy(): void {
    this.isRoleLoaded.unsubscribe();
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  getAll() {
    this.getAllRoles();
    this.getAllGroups();
  }

  getAllRoles() {
    this.roleService.getRoles().subscribe((response) => {
      response.forEach(element => {
        if (this.user.adminuser && !this.user.superuser) {
          if (element.code != 'ADMIN' && element.code != 'SA') {
            this.rolesData.push(element);
          }
        }
        else if (this.user.superuser) {
          this.rolesData.push(element);
        }
      });
      this.rolesDataStorage = this.rolesData;
      this.isRoleLoaded.next(true);
    })
  }

  goToGroupDetails(id){

    //console.log(id);
    this.router.navigate(['accounts/groups/details',id])
  }

  getAllGroups() {
    if (this.user.superuser) {
      this.groupsService.getGroupsForSuperAdmin().subscribe((response) => {
        this.groupsData = response;
        this.groupsDataStorage = response;
      })
    }else{
      this.groupsService.getGroups().subscribe((response) => {
        this.groupsData = response;
        this.groupsDataStorage = response;
      })
    }
    
  }


  handleFilter(id) {
    this.rolesData =
      this.rolesDataStorage.filter((s) => s.name.toLowerCase().indexOf(id.toLowerCase()) !== -1);
  }


  handleFilterGroups(id) {
    this.groupsData =
      this.groupsDataStorage.filter((s) => s.name.toLowerCase().indexOf(id.toLowerCase()) !== -1);
  }

  getUserDetails() {
    this.service.getById(this.userid).subscribe((response) => {
      this.userObj = response;
    })
  }


  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData() {
    this.gridData = {
      data: orderBy(this.items, this.sort),
      total: this.items.length
    };
  }


  getUserGroups() {
    this.form.reset();
    this.service.getGroupsByUser(this.userid).subscribe((response) => {
      if (response) {
        var ids = [];
        response.forEach(element => {
          ids.push(element.id);
        });

        this.form.patchValue({
          member: ids
        })

        this.items = (response) ? (response) : [];
        this.loadData();
      }
      else {
        this.items = [];
        this.loadData();
      }
    },
      (err) => {
        this.items = [];
        this.loadData();
        console.log(err);
      })
  }

  getUserRoles() {
    this.form.reset();
    this.isRoleLoaded.subscribe((res) => {
      if (res) {
        this.service.getRolesByUser(this.userid).subscribe((response) => {
          if (this.user.adminuser && !this.user.superuser) {
            response.forEach(element => {
              if (element.code == "ADMIN") {
                this.isAdminUser = true;
              }
            });
          }

          if (response) {
            var ids = [];
            response.forEach(element => {
              ids.push(element.id);
            });
            this.form.patchValue({
              member: ids
            })
            this.items = (response) ? (response) : [];
            this.loadData();
          }
          else {
            this.items = [];
            this.loadData();
          }
        },
          (err) => {
            this.items = [];
            this.loadData();
            console.log(err);
          })
      }
    })

  }


  getInvitaionLog() {
    this.form.reset();
    this.service.getInvitationLogs(this.userid).subscribe((response) => {
      this.items = (response) ? (response) : [];
      this.loadData();
    },
      (err) => {
        this.items = [];
        this.loadData();
        console.log(err);
      })
  }

  assignRoles() {
    this.service.assignRolesToUser(this.userid, this.form.get('member').value)
      .subscribe((response) => {
        this.isRoleLoaded.next(true);
        // this.getUserRoles();
        this.form.reset();
      })
  }

  assignGroups() {
    this.service.assignGroupsToUser(this.userid, this.form.get('member').value)
      .subscribe((response) => {
        this.getUserGroups();
        this.form.reset();
      })
  }

}
