import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GridDataResult, PageChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Router, ActivatedRoute } from '@angular/router';
import { PasswordSettingsService } from 'src/app/services/settings/password-settings.service';
import { passwordSetting } from 'src/app/entities/password-setting';

@Component({
  selector: 'app-password-settings',
  templateUrl: './password-settings.component.html',
  styleUrls: ['./password-settings.component.css']
})
export class PasswordSettingsComponent implements OnInit {
  form: FormGroup;

  public gridData: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  // private res: any;
  public items: any[] = [];
  // public presentData: any[] = [];

  user:any;
  pwdObj;

  selectableSettings: any
  public checkboxOnly = true;
  public mode = 'multiple';

  public mySelection: string[] = [];
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.id;
  }

  public sort: SortDescriptor[] = [{
    field: 'days',
    dir: 'asc'
  }];

  // rolesArray: role[] = [];
  constructor(
    private fb: FormBuilder,
    private passwordSettings : PasswordSettingsService,
    private route: Router,) {
  }

  ngOnInit() {

    this.user = JSON.parse(sessionStorage.getItem("user"));

    this.form = this.fb.group({
      days: ''
    })

    this.getAll();
  }
  
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData() {
    this.gridData = {
      data: orderBy(this.items, this.sort),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadData()
  }

  getAll() {
    this.passwordSettings.getPasswordSetting().subscribe((data) => {
      this.items = data;
      this.loadData();
    },
      err => {
        this.items = [];
        this.loadData();
        console.log(err);
      });
  }

  getPasswordSettingById(id) {
    this.passwordSettings.getPasswordSettingById(id).subscribe((res) => {
      this.pwdObj = res;
      this.form.patchValue({
        days:res.setting.passwordexpire
      })
    },
      err => {
        console.log(err);
      });
  }

  cancelReset(){
    this.form.reset();
  }

  save(data){
    var obj = new passwordSetting();

    if(this.pwdObj.id){
      obj.id = this.pwdObj.id;
      obj.createdBy.id = this.pwdObj.createdBy.id;
      obj.updatedBy.id = this.user.id;
      obj.setting.passwordexpire = data.days;
      this.passwordSettings.updatePasswordSettings(obj).subscribe((res)=>{
        this.form.reset();
        this.getAll();
      })
    }else{
      obj.createdBy.id = this.user.id;
      obj.updatedBy.id = this.user.id;
      obj.setting.passwordexpire = data.days;
      this.passwordSettings.savePasswordSettings(obj).subscribe((res)=>{
        this.form.reset();
        this.getAll();
      })
    }
  }

}
