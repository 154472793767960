import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridDataResult, RowArgs, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { UserMgntService } from 'src/app/services/usremgnt.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GroupService } from 'src/app/services/group/group.service';
import { UserpermissionsService } from 'src/app/services/user permissions/userpermissions.service';
import { UserService,Modules } from 'src/app/services/user/user.service';
import { Subscription } from 'rxjs';
import { groupPermission } from 'src/app/entities/Permission Models & Enums/group permissions/grouppermission.model';
import { user } from 'src/app/entities/user';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit,OnDestroy {

  form: FormGroup;

  public gridData: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  private res: any;
  public items: any[] = [] ;
  public itemsStorage: any[]= [];

  selectableSettings: any
  public checkboxOnly = true;
  public mode = 'multiple';

  public mySelection: string[] = [];
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.id;
  }

  user = new user();
  
  public sort: SortDescriptor[] = [{
    field: 'facility.display',
    dir: 'asc'
  }];

  permissions: groupPermission = new groupPermission();
  subscription: Subscription = new Subscription();

  
  constructor(
    private fb: FormBuilder,
    private service: GroupService, 
    private permissionService: UserpermissionsService,
    private userService: UserService) { }

  ngOnInit() {

    this.user = this.userService.getSessionUser();    

    this.permissionService.getUserPermissions(Modules.group);
    this.subscription.add(
      this.permissionService.groupPermissions.subscribe((res) => {
        this.permissions = res;
      })
    )

    this.setSelectableSettings();

    this.form = this.fb.group({
      keyword:''
    })

    if(this.user.superuser) {
      this.getAllForSuperAdmin();
    }else{
      this.getAll();
    }
  }

  
  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  
  //deafult setting for patient selection grid
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode
    };
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData() {
    this.gridData = {
      data: orderBy(this.items, this.sort),
      total: this.items.length
    };
  }

  onSelect(event) {
    if (event.selected) {
      this.mySelection.push(event.selectedRows[0].dataItem.id);
    } else {
      var id = event.deselectedRows[0].dataItem.id;
      var i = this.mySelection.findIndex(item => item == id);
      this.mySelection.splice(i,1);
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadData()
  }
  
  getAllForSuperAdmin(){
    this.service.getGroupsForSuperAdmin().subscribe((data) => {      
      this.itemsStorage = data;
      this.items = data;
      this.loadData();
    },
    err => {
      this.items=[];
      this.loadData();
      console.log(err);
    });
    
  }
  
  getAll() {
    this.service.getGroups().subscribe((data) => {      
      this.itemsStorage = data;
      this.items = data;
      this.loadData();
    },
    err => {
      this.items=[];
      this.loadData();
      console.log(err);
    });
  }

  searchRecord() {
    if (this.form.get('keyword').value) {
      this.mySelection = [];
      this.service.searchGroup(this.form.get('keyword').value).subscribe((response) => {        
        this.items = response;
        this.loadData();
      },
        err => {
          this.items = [];
          this.loadData();
          console.log(err);
        });
    }
    else {
      this.mySelection = [];      
      this.items = this.itemsStorage;
      this.loadData();
    }
  }

  search(){
    var keyword = this.form.get('keyword').value;    
    if(keyword) {
      this.items = 
      this.itemsStorage.filter((s) => s.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ;
      this.loadData();
    }
    else {
      this.items = this.itemsStorage;
      this.loadData();
    }
  }

  deleteGroups(){
    this.service.deleteGroups(this.mySelection).subscribe((response) => {
      if(this.user.superuser) {
        this.getAllForSuperAdmin();
      }else{
        this.getAll();
      }
    })
  }

}
