import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { User } from '../../../entities/users';
import { PatientInvitation } from '../../../entities/patient-invitation';
import { InviteService } from '../../../services/Invite-Service/invite.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-user-save',
  templateUrl: './user-save.component.html',
  styleUrls: ['./user-save.component.css']
})
export class UserSaveComponent implements OnInit {

  form: FormGroup;
  user = new User();
  sessionUser: any

  submitted = false;
  userid: string;
  mobDetails: string;

  alphanumericString = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  constructor(
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private inviteService: InviteService,
    private fireauth: AngularFireAuth
  ) { }

  ngOnInit() {

    this.sessionUser = JSON.parse(sessionStorage.getItem("user"));

    this.form = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      emailid: ["", [Validators.required, Validators.email]],
      countryCode: [""],
      mobile: [''],
      emailidDuplicate: [false, ''],
      checkEmail: ['', [Validators.required]],
      isallowtosendEmail: false
    })
    this.activateRoute.params.subscribe((param) => {
      if (param.id) {
        this.userid = param.id;
        this.getUserDetails(this.userid);
      }
    })
  }

  getUserDetails(id) {

    this.userService.getById(id).subscribe((response) => {
      this.user = response;
      this.mobDetails = response.telecom.mobile;
      if (this.mobDetails && this.mobDetails.length > 10) {
        var mob = this.mobDetails.slice((this.mobDetails.length - 10), this.mobDetails.length)
        var cCode = this.mobDetails.slice(0, (this.mobDetails.length - 10))
        // console.log(mob);
        // console.log(cCode);
        this.form.patchValue({
          countryCode: cCode.split("+")[1],
          mobile: mob
        })
      } else {
        this.form.patchValue({
          countryCode: '',
          mobile: response.telecom.mobile
        })
      }
      this.form.patchValue({
        firstname: response.name.firstname,
        lastname: response.name.lastname,
        emailid: response.telecom.email,
        checkEmail: true
      })
    })
  }

  CheckAvailable() {
    let email = this.form.get('emailid').value;
    if (this.user.username == email) {
      this.form.patchValue({
        emailidDuplicate: false,
        checkEmail: true
      })
    } else {
      this.userService.CheckUserNameAvailability(email).subscribe((res) => {
        if (!res) {
          //this.patientForm.controls['emailid'].reset();
          this.form.controls['checkEmail'].reset();
          this.form.patchValue({
            emailidDuplicate: true,
          })
        }
        else {
          this.form.patchValue({
            emailidDuplicate: false,
            checkEmail: true
          })
        }
      })

    }
  }

  // valuChanged() {
  //   console.log(this.form.valid);
  //   let countryCode = this.form.get('countryCode').value;
  //   let mobile = this.form.get('mobile').value;
  //   console.log(countryCode);
  //   console.log(mobile);
  //   if (mobile) {
  //     if (countryCode == "") {
  //       this.form.controls['countryCode'].setValidators([Validators.required]);
  //       this.submitted = false;
  //     } else {
  //       this.submitted = true;
  //       this.form.controls['countryCode'].clearValidators();
  //     }
  //   } else {
  //     this.submitted = true;
  //     this.form.controls['countryCode'].clearValidators();
  //   }
  // }

  saveData(data) {
    this.submitted = true;
    let objUser = new User();
    objUser.id = this.userid;
    objUser.name.firstname = data.firstname;
    objUser.name.lastname = data.lastname;
    objUser.telecom.emailid = data.emailid;
    objUser.telecom.mobile = "+" + data.countryCode + "" + data.mobile;
    objUser.username = data.emailid;
    objUser.url = '';
    objUser.link.id = '';
    objUser.linkresource = '';

    if (!this.userid) {
      // this.fireauth.auth.createUserWithEmailAndPassword(objUser.telecom.emailid,objUser.password)
      // .then((response) => {
      // })
      objUser.firebaseid = "";
      objUser.password = this.randomString(6, this.alphanumericString);
      this.saveUser(objUser, data);

    } else {
      // objUser.password = this.user.password;
      this.updateUser(objUser)
    }
  }

  saveUser(data: User, formData) {
    data.firebaseid = "";
    this.userService.saveUser(data).subscribe((res) => {
      if (formData.isallowtosendEmail) {
        this.sendInvitavitaion(res.id, data);
      }
      this.router.navigate(['accounts/users'])
    })
  }

  updateUser(data) {
    this.userService.updateUser(data).subscribe((res) => {
      // this.updateInvitavitaion(res.id, data.emailid, data.mobile);
      this.router.navigate(['accounts/users'])

    })
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  sendInvitavitaion(id, data: User) {
    let password = data.password;
    let email = data.telecom.emailid;
    let mobile = data.telecom.mobile;

    let obj = new PatientInvitation();
    obj.emailid = email,
      obj.mobile = mobile,
      obj.sendby.id = this.userid,
      obj.message = "You are successfully Registered in QPathway and Your one time password is: ",
      obj.patientid = id,
      obj.module = 'Invitation',
      obj.resourcetype = 'User';
    obj.password = password;

    this.inviteService.inviteUser(obj)
      .subscribe((response) => {
      },
        (ex) => {
          console.log(ex);
          //this.exception = ex;
        })
  }

  updateInvitavitaion(id, email, mobile) {
    let obj = new PatientInvitation();
    obj.emailid = email,
      obj.mobile = mobile,
      obj.patientid = id,
      obj.sendby.id = '123',
      obj.message = "You are successfully Registered in QPathway and Your one time password is: ",
      obj.module = 'Invitation',
      obj.resourcetype = 'User'

    this.inviteService.updateInvitation(obj)
      .subscribe((response) => {

      },
        (ex) => {
          console.log(ex);
          //this.exception = ex;
        })
  }

  goBack() {
    if (this.userid) {
      this.router.navigate(['accounts/users/details', this.userid])
    }
    else {
      this.router.navigate(['accounts/users'])
    }
  }

}
