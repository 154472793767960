import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserMgntService {

  private config = environment;

  constructor(private http: HttpClient) {
  }

  getPermissions() {
    return this.http.get<any[]>(this.config.baseurl + "permission")
  }

  getAccounts() {
    return this.http.get<any[]>(this.config.baseurl + "person");
  }

  searchPermission(keyword) {
    let params = new HttpParams()
      .set('keyword', keyword)
    return this.http.get<any[]>(this.config.baseurl + "permission/search", { params })
  }

  

  searchUsers(keyword) {
    let params = new HttpParams()
      .set('keyword', keyword)
    return this.http.get<any[]>(this.config.baseurl + "user/search", { params })
  }

 

}
