import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PublicLayoutComponent } from "./public-layout/public-layout.component";
import { publicRoutes, authRoutes } from "./app.routing";
import { PrivateLayoutComponent } from "./private-layout/private-layout.component";
import { LoginComponent } from "./login/login.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HeaderComponent } from "./header/header.component";
import { GridModule } from "@progress/kendo-angular-grid";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccountsComponent } from "./core/Account Components/accounts/accounts.component";
import { AccountSaveComponent } from "./core/Account Components/account-save/account-save.component";
import { GroupsComponent } from "./core/Groups Components/groups/groups.component";
import { PermissionsComponent } from "./core/Permission Components/permissions/permissions.component";
import { RolesComponent } from "./core/Roles Components/roles/roles.component";
import { GroupSaveComponent } from "./core/Groups Components/group-save/group-save.component";
import { RoleSaveComponent } from "./core/Roles Components/role-save/role-save.component";
import { UsersComponent } from "./core/User Components/users/users.component";
import { ErrorComponent } from "./Shared Components/error/error.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "src/environments/environment";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { GroupDetailsComponent } from "./core/Groups Components/group-details/group-details.component";
import { RoleDetailsComponent } from "./core/Roles Components/role-details/role-details.component";
import { InputsModule } from "@progress/kendo-angular-inputs";

import { TreeViewModule } from "@progress/kendo-angular-treeview";

import { UserSaveComponent } from "./core/User Components/user-save/user-save.component";
import { UserDetailsComponent } from "./core/User Components/user-details/user-details.component";
import { PhonePipe } from "./Shared Components/phone.pipe";
import { ValidateUserComponent } from "./validate-user/validate-user.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { JwtInterceptor } from "./services/interceptors/jwt.interceptor";
import { ErrorInterceptor } from "./services/interceptors/error.interceptor";
import { AuthGuard } from "./services/auth guard/auth.guard";
import { SettingsComponent } from "./core/Settings/settings/settings.component";
import { PasswordSettingsComponent } from "./core/Settings/password-settings/password-settings.component";
import { InactivityTimerComponent } from "./Shared Components/user session mgnt & service/inactivity-timer.component";

const appRoutes: Routes = [
  {
    path: "",
    component: PublicLayoutComponent,
    children: publicRoutes
  },
  {
    path: "",
    // canActivate: [AuthGuard],
    component: PrivateLayoutComponent,
    children: authRoutes
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,

    AccountsComponent,
    AccountSaveComponent,

    GroupsComponent,
    PermissionsComponent,
    RolesComponent,
    GroupSaveComponent,
    RoleSaveComponent,
    UsersComponent,
    ErrorComponent,
    GroupDetailsComponent,
    RoleDetailsComponent,
    UserSaveComponent,
    UserDetailsComponent,

    PhonePipe,
    ValidateUserComponent,
    SettingsComponent,
    PasswordSettingsComponent,
    InactivityTimerComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,

    GridModule,
    DropDownsModule,
    DateInputsModule,
    DropDownsModule,
    InputsModule,
    TreeViewModule,

    BrowserAnimationsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
