import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UserService } from "../user/user.service";
import { grouptree } from "src/app/entities/group";
import { groupTreeStructure } from "src/app/entities/groupTreeStructure";

@Injectable({
  providedIn: "root"
})
export class GroupService {
  private config = environment;

  constructor(private http: HttpClient, private userService: UserService) {}

  getGrouptree(id) {
    return this.http.get<groupTreeStructure[]>(
      this.config.baseurl + "grouptree/" + id,
      { observe: "response" }
    );
  }

  getGroupParents(id) {
    return this.http.get<any>(this.config.baseurl + "groupbyid/" + id);
  }

  getGroupChildrens(id) {
    return this.http.get<any>(this.config.baseurl + "groupdetails/" + id);
  }

  getGroupUsers(ids) {
    var obj = {
      groups: [ids]
    };
    return this.http.post<any>(
      this.config.baseurl + "personlookupbygroup",
      obj
    );
  }

  getById(id) {
    return this.http.get<any>(this.config.baseurl + "groupbyid/" + id);
  }

  getGroupsForSuperAdmin() {
    return this.http.get<any[]>(this.config.baseurl + "group");
  }

  getGroups() {
    var user = this.userService.getSessionUser();
    return this.http.get<any[]>(this.config.baseurl + "usergroups/" + user.id);
  }

  searchGroup(keyword) {
    let params = new HttpParams().set("keyword", keyword);
    return this.http.get<any[]>(this.config.baseurl + "group/search", {
      params
    });
  }

  deleteGroups(ids) {
    var obj = {
      id: ids
    };
    return this.http.post<any>(this.config.baseurl + "group/delete", obj);
  }

  createGroup(role) {
    return this.http.post<any>(this.config.baseurl + "group", role);
  }

  updateGroup(role) {
    return this.http.put<any>(this.config.baseurl + "group", role);
  }
}
