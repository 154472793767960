import { Component, OnInit, OnDestroy } from '@angular/core';

import { GridDataResult, RowArgs, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RoleService } from 'src/app/services/role/role.service';
import { DataService } from 'src/app/services/Data/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { rolePermission } from 'src/app/entities/Permission Models & Enums/role permissions/rolepermission.model';
import { Subscription } from 'rxjs';
import { Modules, UserService } from 'src/app/services/user/user.service';
import { UserpermissionsService } from 'src/app/services/user permissions/userpermissions.service';
import { user } from 'src/app/entities/user';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit,OnDestroy {

  form: FormGroup;

  public gridData: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  private res: any;
  public items: any[] = [];
  public presentData: any[] = [];

  selectableSettings: any
  public checkboxOnly = true;
  public mode = 'multiple';

  public mySelection: string[] = [];
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.id;
  }

  public sort: SortDescriptor[] = [{
    field: 'facility.display',
    dir: 'asc'
  }];

  
  permissions: rolePermission = new rolePermission();
  subscription: Subscription = new Subscription();

  user = new user();

  // rolesArray: role[] = [];
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private service: RoleService,
    private permissionService: UserpermissionsService,
    private userService: UserService) {
  }

  ngOnInit() {
    this.user = this.userService.getSessionUser();
    this.permissionService.getUserPermissions(Modules.role);
    this.subscription.add(
      this.permissionService.rolePermissions.subscribe((res) => {
        this.permissions = res;
      })
    )
    
    this.form = this.fb.group({
      keyword: ''
    })
    this.setSelectableSettings();
    if(this.user.superuser) {
    this.getAll();
    }
  }
  
  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }



  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData() {
    this.gridData = {
      data: orderBy(this.items, this.sort),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadData()
  }

  //deafult setting for patient selection grid
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode
    };
  }

  onSelect(event) {    
    if (event.selected) {
      this.mySelection.push(event.selectedRows[0].dataItem.id);
    } else {
      var id = event.deselectedRows[0].dataItem.id;
      var i = this.mySelection.findIndex(item => item == id);
      this.mySelection.splice(i,1);
    }
  }


  getAll() {
    this.service.getRoles().subscribe((data) => {
      // data.forEach(element => {
      //   if(element.active){
      //     this.mySelection.push(element.id);
      //   }
      // });
      
      this.presentData = data;
      this.items = data;
      this.loadData();
    },
      err => {
        this.items = [];
        this.loadData();
        console.log(err);
      });
  }

  searchRecord() {
    if (this.form.get('keyword').value) {
      this.mySelection = [];
      this.service.searchRoles(this.form.get('keyword').value).subscribe((response) => {        
        this.items = response;
        this.loadData();
      },
        err => {
          this.items = [];
          this.loadData();
          console.log(err);
        });
    }
    else {
      this.mySelection = [];      
      this.items = this.presentData;
      this.loadData();
    }
  }

  deleteRoles(){
    this.service.deleteRoles(this.mySelection).subscribe((response) => {
      this.getAll();
    })
  }
}
