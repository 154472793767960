import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from 'src/app/services/role/role.service';
import { GridDataResult, PageChangeEvent, SelectAllCheckboxState, RowArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder } from '@angular/forms';
import { rolePermission } from 'src/app/entities/Permission Models & Enums/role permissions/rolepermission.model';
import { Subscription } from 'rxjs';
import { UserpermissionsService } from 'src/app/services/user permissions/userpermissions.service';
import { Modules } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.css']
})
export class RoleDetailsComponent implements OnInit,OnDestroy {

  roleid: string;
  roleObj: any;

  public gridData: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  private res: any;
  public items: any[]=[];
  public itemsStorage: any[];

  public presentData: any[];

  userList: any[]=[];
  
  public sort: SortDescriptor[] = [{
    field: 'facility.display',
    dir: 'asc'
  }];

  searchForm: FormGroup;

  
  permissions: rolePermission = new rolePermission();
  subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private service : RoleService,
    private permissionService: UserpermissionsService
    ) { }

  ngOnInit() {
    this.permissionService.getUserPermissions(Modules.role);
    this.subscription.add(
      this.permissionService.rolePermissions.subscribe((res) => {
        this.permissions = res;
      })
    )

    this.searchForm = this.fb.group({
      keyword: ''
    })

    this.activateRoute.params.subscribe((param) => {
      if(param.id){
        this.roleid = param.id;
        this.getRoleDetails();
        this.getRolePermissions();
      }
    })
  }

  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  getRoleDetails(){
    this.service.getById(this.roleid).subscribe((response) => {      
      this.roleObj =response;
    })
  }



  getRolePermissions(){
    this.service.getPermisisonsForrole(this.roleid).subscribe((response) => {     
      
      this.items = [];
      this.presentData = response;
      this.items= response;
      this.itemsStorage = response;
      this.loadData();
    },
    err => {
      this.items=[];
      this.loadData();
      console.log(err);
    });
  }

  ///////////////////////////////////////////////

  public permissiongridData: GridDataResult;
  public permissionsItems: any[];

  LoadPermissions(){
    this.getAllPermissions();
    
    if (this.items != null) {
      this.mySelection_Permissions =[];
      this.items.forEach(element => {
        this.mySelection_Permissions.push(
          element.id + "|" + element.code + "|" + element.modulename + "|" + element.description
        )
      });
    }
    
  }

  allPermissions : any;
  getAllPermissions() {
    this.service.getAllPermissions().subscribe((data) => {
      this.permissionsItems = data;
      this.allPermissions = data;
      this.loadPermissionData();
    })
  }

  private loadPermissionData(): void {
    this.permissiongridData = {
      data: this.permissionsItems.slice(this.permission_skip, this.permission_skip + this.permission_pageSize),
      total: this.permissionsItems.length
    }
  }

  RemovePermission(dataitem){

    let toRemoveItem : any[] =[];

    toRemoveItem.push(dataitem.id);

    this.service.removeRolePermissions(this.roleid,toRemoveItem).subscribe((response) => {
      this.getRolePermissions();
    })
   
    
  }

  searchPermission() {
    if (this.searchForm.get('keyword').value) {
      this.service.searchPermission(this.searchForm.get('keyword').value).subscribe((response) => {
        this.permissionsItems = response;
        this.loadPermissionData();
        // response.forEach(element => {
        //   var obj = {
        //     code:element.code,
        //     description:element.permission_desc
        //   }
        //   this.permissionsItems.push(obj);
        // });

        // // this.items = response;
        // this.loadData();
      },
        err => {
          this.permissionsItems = [];
          this.loadPermissionData();
          //console.log(err);
        });
    }
    else {
      this.permissionsItems = this.allPermissions;
      this.loadPermissionData();
    }
  }

  public permission_pageSize = 50;
  public permission_skip = 0;

  public permission_pageChange(event: PageChangeEvent): void {
    this.permission_skip = event.skip;
    this.loadPermissionData();
  }


  
public selectAllState_Permissions: SelectAllCheckboxState = 'unchecked';
public mySelection_Permissions: string[] = [];
public mySelectionKey_Permissions(context: RowArgs): string {

  return context.dataItem.id + "|" + context.dataItem.code + "|" + context.dataItem.module_desc + "|" + context.dataItem.permission_desc;
}


public onSelectAllChange_Permissions(checkedState: SelectAllCheckboxState) {
  if (checkedState === 'checked') {
    
    this.mySelection_Permissions = this.permissionsItems.map((item) => item.dataItem.id + "|" + item.dataItem.code + "|" + item.dataItem.module_desc + "|" + item.dataItem.permission_desc);
    this.selectAllState_Permissions = 'checked';
  } else {
    this.mySelection_Permissions = [];
    this.selectAllState_Permissions = 'unchecked';
  }
}
public onSelectedKeysChange_Permissions(e) {
  const len = this.mySelection_Permissions.length;


  if (len === 0) {
    this.selectAllState_Permissions = 'unchecked';
  // } else if (len > 0 && len < this.arractivities.length) {
  //   this.selectAllState_Permissions = 'indeterminate';
  } else {
    this.selectAllState_Permissions = 'checked';
  }
}

SavePermission(){
  let finalSelectedPermissions : any=[];
  this.mySelection_Permissions.forEach(element => {
    let temparr = element.split("|");
      finalSelectedPermissions.push(temparr[0]);
  });
  
  this.savePermissionsForRole(finalSelectedPermissions);
}



savePermissionsForRole(finalSelectedPermissions) {
  
  this.service.addPermissionsToRole(this.roleid, finalSelectedPermissions).subscribe((response) => {
    
    this.getRolePermissions(); 
  })
}

  //////////////////////////////////////////////
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadData();
  }

  
  private loadData(): void {
    if (this.items != null) {
      this.gridData = {
        data: this.items.slice(this.skip, this.skip + this.pageSize),
        total: this.items.length
      }
    }
  
  }

  searchRecord() {
    if(this.searchForm.get('keyword').value){      
      this.service.searchPermission(this.searchForm.get('keyword').value).subscribe((response) => {
        response.forEach(element => {
          var obj = {
            code:element.code,
            description:element.permission_desc
          }
          this.items.push(obj);
        });
        
        // this.items = response;
        this.loadData();
      },
      err => {
        this.items=[];
        this.loadData();
        console.log(err);
      });
    }
    else {
      this.items = this.presentData;
      this.loadData();
    }
  }

  search(){
    var keyword = this.searchForm.get('keyword').value;    
    if(keyword){
      this.items = 
      this.itemsStorage.filter((s) => s.description.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
      this.itemsStorage.filter((s) => s.code.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
      ;
      this.loadData();
    }
    else {
      this.items = this.presentData;
      this.loadData();
    }

  }

  getUsers() {

    this.service.getUsersByRole(this.roleid).subscribe((response) => {
    //   this.userList = response;
    // })
    if(response){
      // this.groupUsers = response;
      this.items = [];
      this.items = response;
      this.loadData();
      }
    },
    (err) => {
      this.items=[];
      this.loadData();
      console.log(err);
    })
  }

  goToUserDetails(id){
    this.router.navigate(['accounts/users/details',id])
  }

}
