import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridDataResult, PageChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { UserService, Modules } from 'src/app/services/user/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InviteService } from 'src/app/services/Invite-Service/invite.service';
import { resetPassword } from 'src/app/entities/resetPassword';
import { UserpermissionsService } from 'src/app/services/user permissions/userpermissions.service';
import { userPermission } from 'src/app/entities/Permission Models & Enums/user permissions/userpermission.model';
import { Subscription } from 'rxjs';
import { user } from 'src/app/entities/user';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit,OnDestroy {
  public gridData: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  private res: any;
  public items: any[] = [];
  public itemsStorage: any[] = [];
  public presentData: any[] = [];
  objReset;

  type: any;
  customSelect;
  list: any;
  public sort: SortDescriptor[] = [{
    field: 'fullname',
    dir: 'asc'
  }];

  selectableSettings: any
  public checkboxOnly = true;
  public mode = 'multiple';

  public mySelection: string[] = [];
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.id;
  }
  form: FormGroup;
  resetForm: FormGroup;
  autogeneratedForm: FormGroup;
  manualForm: FormGroup;

  maskmobile="(999) 000-0000";
  userid:string;

  permissions: userPermission = new userPermission();
  subscription: Subscription = new Subscription();

  user = new user();

  constructor(
    private fb: FormBuilder,
    private service: UserService,
    private inviteService:InviteService,
    private permissionService: UserpermissionsService,
    private userService: UserService
    ) {

  }
  
  ngOnInit() {

    this.user = this.userService.getSessionUser();    

    this.permissionService.getUserPermissions(Modules.user);
    this.subscription.add(
      this.permissionService.userPermissions.subscribe((res) => {
        this.permissions = res;
      })
    )

    this.setSelectableSettings();
    this.form = this.fb.group({
      keyword:''
    })
    // this.resetForm = this.fb.group({
    //   email:[""],
    //   mobile:[""],
    //   optionSelected:['email',Validators.required],
    //   checkEmail:[false,],
    //   checkMobile:[false,],
    //   password:[""]
    // })
    this.autogeneratedForm = this.fb.group({
      email:[""],
      mobile:[""],
      optionSelected: ["", [Validators.required]],
      checkEmail:[false,],
      checkMobile:[false,]
    })

    this.manualForm = this.fb.group({
      password:[""]
    })

    if (this.user.superuser) {
      this.GetAllForSuperAdmin();
    } else {
      this.GetAll();
    }

  }

  
  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData() {
    this.gridData = {
      data: orderBy(this.items, this.sort),
      total: this.items.length
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadPatient();

  }

  private loadPatient(): void {
    this.gridData = {
      data: this.items.slice(this.skip, this.skip + this.pageSize),
      total: this.items.length

    }
  }

  
   //deafult setting for patient selection grid
   public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode
    };
  }

  GetAllForSuperAdmin(){
    this.service.getUsersForSuperAdmin().subscribe((res) => {            
      if(res) {
        this.itemsStorage = res;
        this.items = res;
        this.presentData = res;
        this.loadPatient();
      }
    },
      err => {
        this.items=[];
        this.loadPatient();
        console.log(err);
      });
  }

  GetAll() {
    this.service.getUsers().subscribe((res) => {            
      if(res) {
        this.itemsStorage = res;
        this.items = res;
        this.presentData = res;
        this.loadPatient();
      }
    },
      err => {
        this.items=[];
        this.loadPatient();
        console.log(err);
      });
  }

  searchRecord() {
    if(this.form.get('keyword').value){      
      this.mySelection = [];
      this.service.searchUser(this.form.get('keyword').value).subscribe((response) => {
        this.items = response;
        this.loadData();
      },
      err => {
        this.items=[];
        this.loadData();
        console.log(err);
      });
    }
    else {
      this.mySelection = [];
      this.items = this.itemsStorage;
      this.loadData();
    }
  }

    
  onSelect(event) {
    if (event.selected) {
      this.mySelection.push(event.selectedRows[0].dataItem.id);
    } else {
      var id = event.deselectedRows[0].dataItem.id;
      var i = this.mySelection.findIndex(item => item == id);
      this.mySelection.splice(i,1);
    }
  }

  search(){
    var keyword = this.form.get('keyword').value;    
    if(keyword) {
      this.items = 
      this.itemsStorage.filter((s) => s.fullname.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
      this.itemsStorage.filter((s) => s.telecom.email.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)||
      this.itemsStorage.filter((s) => s.telecom.mobile.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
      ;
      this.loadData();
    }
    else {
      this.items = this.presentData;
      this.loadData();
    }
  }

  reset(obj) {
    // this.resetForm.controls['mobile'].reset();
    // this.resetForm.controls['email'].reset();
    this.autogeneratedForm.reset();
    this.manualForm.reset();
    this.customSelect='';
    this.userid = obj.id;
    this.objReset = obj;
    this.autogeneratedForm.patchValue({
      email:obj.telecom.email,
      mobile:obj.telecom.mobile
    })
  }

  onOptionSelectedChange(e){
    var mobile = this.autogeneratedForm.get('checkMobile').value;
    var email = this.autogeneratedForm.get('checkEmail').value;
    
    if(email || mobile){
      this.autogeneratedForm.patchValue({
        optionSelected:true
      })
    }else{
      this.autogeneratedForm.patchValue({
        optionSelected:""
      })
    }
    
  }

  onCustomSelectChange(e){
    if(e.target.value=='autogenerated'){
      this.manualForm.reset();
    }else if(e.target.value=='manual'){
      this.autogeneratedForm.reset();
      this.autogeneratedForm.patchValue({
        email:this.objReset.telecom.email,
        mobile:this.objReset.telecom.mobile
      })
    }
  }

  sendResetNotification(){

    // var option = this.resetForm.get('optionSelected').value;
    var mobile = this.autogeneratedForm.get('mobile').value;
    var email = this.autogeneratedForm.get('email').value;
    var password = this.manualForm.get('password').value;
    
    if(this.customSelect=='autogenerated'){
      let obj: resetPassword ={
        userid:this.userid,
        mobile: (mobile) ? mobile:'',
        emailid:(email) ? email:'',
      }
      
      this.inviteService.resetPassword(obj).subscribe((response) => {
        this.autogeneratedForm.reset();
        this.manualForm.reset();
      },
      (ex) => {
        console.log(ex);
      })
    }else if(this.customSelect=='manual'){
      
      let obj: any ={
        personid: this.userid,
        password: password
      }
      this.service.resetPassword(obj).subscribe((response) => {
        this.autogeneratedForm.reset();
        this.manualForm.reset();
      },
      (ex) => {
        console.log(ex);
      })
    }
  }
}
