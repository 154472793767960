import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  private config = environment;

  constructor(private http: HttpClient) { }

  sendInvitation(obj) {
    return this.http.post(this.config.baseurl + "invitation/save", obj);
  }

  inviteUser(obj) {
    return this.http.post(this.config.baseurl + "invitation_auth/save", obj);
  }


  updateInvitation(obj) {
    return this.http.post(this.config.baseurl + "invitation/update", obj);
  }

  resetPassword(obj) {
    return this.http.post(this.config.baseurl + "resetpassword",obj)
  }
}
