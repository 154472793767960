import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from '../sessionStorage/session-storage.service';
import { Subject } from 'rxjs';
import { Modules } from '../user/user.service';
import { GroupsEnum } from 'src/app/entities/Permission Models & Enums/group permissions/group.enum';
import { groupPermission } from 'src/app/entities/Permission Models & Enums/group permissions/grouppermission.model';
import { RoleEnum } from 'src/app/entities/Permission Models & Enums/role permissions/role.enum';
import { rolePermission } from 'src/app/entities/Permission Models & Enums/role permissions/rolepermission.model';
import { userEnum } from 'src/app/entities/Permission Models & Enums/user permissions/user.enum';
import { userPermission } from 'src/app/entities/Permission Models & Enums/user permissions/userpermission.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserpermissionsService {

  private config = environment;
  
  groupPermissions = new Subject<groupPermission>();
  groupPermissionObj = new groupPermission();

  rolePermissions = new Subject<rolePermission>();
  rolePermissionObj = new rolePermission();

  userPermissions = new Subject<userPermission>();
  userPermissionObj = new userPermission();

  constructor(
    private http: HttpClient,
    private sessionService: SessionStorageService
  ) { }

  getUserPermissions(moduleid) {
    var obj = {
      moduleid: moduleid,
      id: this.sessionService.getSession("roles")
    }
    
    this.http.post<any[]>(this.config.baseurl + "permissionarray", obj)
      .subscribe((response) => {        
        if(response) {
        if (moduleid === Modules.group) {
          this.groupPermissions.next({
            add: response.includes(GroupsEnum.add),
            delete: response.includes(GroupsEnum.delete),
            edit: response.includes(GroupsEnum.edit),
            view: response.includes(GroupsEnum.view),
          })
        }
        else if (moduleid === Modules.role) {
          this.rolePermissions.next({
            add: response.includes(RoleEnum.add),
            delete: response.includes(RoleEnum.delete),
            update: response.includes(RoleEnum.update),
          })
        }
        else if (moduleid === Modules.user) {
          this.userPermissions.next({
            add: response.includes(userEnum.add),
            update: response.includes(userEnum.update),
            assignRole: response.includes(userEnum.assignRole),
            assignGroup: response.includes(userEnum.assignGroup),
            resetPassword: response.includes(userEnum.resetPassword),
          })
        }
      }
      })
    
  }
}
