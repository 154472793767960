export class group {
    id: string;
    name: string;
    description: string;
    createdby: string;
    active: boolean;
    type: string;
    members: string[]=[];
    memberof: string;
}

export class grouptree{
    id: string;
    name: string;
    isactive: boolean;
    description: string;
    parenttree: treedetails[]=[];
    childtree:treedetails[] = [];
} 

export class treedetails{
    id:string;
    name:string;
 }