import { Component, OnInit } from '@angular/core';
import { GridDataResult, SelectAllCheckboxState, RowArgs, SelectableSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleService } from 'src/app/services/role/role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { role } from 'src/app/entities/role';

@Component({
  selector: 'app-role-save',
  templateUrl: './role-save.component.html',
  styleUrls: ['./role-save.component.css']
})
export class RoleSaveComponent implements OnInit {

  public permissiongridData: GridDataResult;
  public permission_pageSize = 50;
  public permission_skip = 0;
  public permission_items: any[] = [];
  public permission_itemsStorage: any[] = [];


  selectedPermissions_items: any[] = [];
  selectedpermissions: string[] = [];


  public checkboxOnly = true;
  public mode = 'multiple';
  public selectableSettings: SelectableSettings;


  public arrPermissions: any[] = [];
  public arrselPermissions: any[] = [];

  public selectAllState_Per: SelectAllCheckboxState = 'unchecked';
  public mySelection_Per: string[] = [];
  public mySelectedItems: any[] = [];



  form: FormGroup;
  searchForm: FormGroup;

  submitted = false;

  roleid: string;
  roleObj = new role();

  finalSelectedItems : string[]=[];
  finalRemovedItems : string[]=[];
  user:any;
  
  constructor(
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private service: RoleService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));

    this.setSelectableSettings();
    this.searchForm = this.fb.group({
      keyword: '',
    })
    this.form = this.fb.group({
      code: ['',[Validators.required]],
      name: ['',[Validators.required]],
      isCodeDuplicate: [false, ''],
      checkCode: ['', [Validators.required]],
      description: '',
      isSysRole: false
    })
    this.activateRoute.params.subscribe((param) => {
      if (param.id) {
        this.roleid = param.id;
        this.getPermissionsForRole();
        this.getRoledetails(this.roleid);
      }
    })
  }

  getRoledetails(id) {
    this.service.getById(id).subscribe((response) => {      
      this.roleObj = response;
      this.form.patchValue({
        code: response.code,
        name: response.name,
        description: response.description,
        checkCode: true,
        isSysRole: (response.type === "system") ? true:false
      })
    })
  }

  getPermissionsForRole() {
    this.service.getPermisisonsForrole(this.roleid).subscribe((response) => {      
      this.mySelectedItems = response;
      this.selectedPermissions_items = response;
      response.forEach(element => {
        this.mySelection_Per.push(element.id)
      });
    })
  }


  getAllPermissions() {
    this.service.getAllPermissions().subscribe((data) => {

      data.forEach(element => {
        this.mySelection_Per.forEach(id => {
          if(element.id === id) {
            var index = data.findIndex(res => res.id === id);
            data.splice(index,1)
          }
        });
      });

      this.permission_items = data;
      this.permission_itemsStorage = data;
      this.loadPermissions();
    })
  }

  onSelect(event) {
    if (event.selected) {
      var obj = event.selectedRows[0].dataItem;
      this.mySelectedItems.push({
        id:obj.id,
        code:obj.code,
        description:obj.permission_desc,
        name:''
      })
      this.mySelection_Per.push(event.selectedRows[0].dataItem.id);
      this.finalSelectedItems.push(obj.id)

    } else {
      var id = event.deselectedRows[0].dataItem.id;

      var i = this.mySelection_Per.findIndex(item => item == id);
      this.mySelection_Per.splice(i, 1);

      var index = this.mySelectedItems.findIndex(item => item.id == id);
      this.mySelectedItems.splice(index, 1);

    }
  }


  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: 'multiple'
    };
  }


  private loadPermissions(): void {
    this.permissiongridData = {
      data: this.permission_items.slice(this.permission_skip, this.permission_skip + this.permission_pageSize),
      total: this.permission_items.length
    }
  }

  public permission_pageChange(event: PageChangeEvent): void {
    this.permission_skip = event.skip;
    this.loadPermissions();
  }

  public mySelectionKey_Per(context: RowArgs): string {
    return context.dataItem.id;
  }


  addPermission() {
    this.selectedPermissions_items = [];
    this.selectedPermissions_items = this.mySelectedItems;
  }

  savePermissionsForRole() {
    this.service.addPermissionsToRole(this.roleid, this.finalSelectedItems).subscribe((response) => {
      // this.getPermissionsForRole();
    })
  }


  removePermissionFromGrid(index,id) {

    this.finalRemovedItems.push(id);
    this.selectedPermissions_items.splice(index,1);
    
    var i = this.mySelection_Per.findIndex(item => item === id);
    this.mySelection_Per.splice(i, 1);
      
    // var index1 = this.mySelectedItems.findIndex(item => item.id === id);
    // this.mySelectedItems.splice(index1, 1);

  }

  removePermissions(){
    this.service.removeRolePermissions(this.roleid,this.finalRemovedItems).subscribe((response) => {
    })
  }

  saveData(data) {

    var obj = new role();

    obj.id = this.roleid;
    obj.code = data.code;
    obj.name = data.name;
    obj.description = data.description;
    obj.createdby = this.user.id;
    obj.active = true;
    obj.type = (data.isSysRole) ?  "system":"default";

    if (this.roleid) {
     // obj.premissions = null;
      this.service.updateRole(obj).subscribe((response) => {
        // this.savePermissionsForRole();
        // if(this.finalRemovedItems){
        //   this.removePermissions();
        // }
        this.router.navigate(['accounts/roles'])
      })
    }
    else {
      // var ids: string[] = [];
      // this.selectedPermissions_items.forEach(element => {
      //   ids.push(element.id);
      // });
      // obj.premissions = ids;
      this.service.createRole(obj).subscribe((response) => {
        this.router.navigate(['accounts/roles'])
      })
    }
  }

  searchRecord() {
    if (this.searchForm.get('keyword').value) {
      this.service.searchPermission(this.searchForm.get('keyword').value).subscribe((response) => {
        this.permission_items = response;
        this.loadPermissions();
      },
        err => {
          this.permission_items = [];
          this.loadPermissions();
          console.log(err);
        });
    }
    else {
      this.permission_items = this.permission_itemsStorage;
      this.loadPermissions();
    }
  }

  CheckAvailable() {
    let code = this.form.get('code').value;
    if (this.roleObj.code == code) {
      this.form.patchValue({
        isCodeDuplicate: false,
        checkCode: true
      })
    } else {
      this.service.checkCodeAvailability(code).subscribe((res) => {
        
        if (res) {
          // this.form.controls['code'].reset();
          this.form.patchValue({
            isCodeDuplicate: true,
            checkCode: ""
          })
        }
        else {
          this.form.patchValue({
            isCodeDuplicate: false,
            checkCode: true
          })
        }
      })

    }
  }

  goBack() {
    if(this.roleid){
      this.router.navigate(['accounts/roles/details',this.roleid])
    }
    else {
      this.router.navigate(['accounts/roles'])
    }
  }




}
